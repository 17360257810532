<template>
    <sm-editable-item
      v-model="form"
      :controllerName="controllerName"
      :isLoading="employeeAccounts.isLoading"
      :breadcrumbs="breadcrumbs"
      :pageHeader="pageHeader"
      :fields="fields"
      @save="onSave('AccountsToBlockClientMessages')"
      @cancel="onCancel('AccountsToBlockClientMessages')"
    />
  </template>
  
  <script>
  // mixins
  import editableListItemCreate from '@/mixins/editableListItemCreate.js';
  // vuex
  import { mapState, mapActions } from 'vuex';
  // components
  import SmEditableItem from '@/components/views/SmEditableItem.vue';
  
  export default {
    name: 'AccountsToBlockClientMessageCreate',
  
    components: {
      SmEditableItem,
    },
  
    mixins: [editableListItemCreate],
  
    data() {
      return {
        controllerName: 'AccountsToBlockClientMessages',
        pageHeader: 'Создание сотрудника, которому нельзя писать в заявке КСП',
        breadcrumbs: [
          {
            text: 'Администрирование',
            route: { name: 'AdministrationMain' },
          },
          {
            text: 'Сотрудники, которым нельзя писать в заявку КСП',
            route: { name: 'AccountsToBlockClientMessages' },
          },
          {
            text: 'Создание сотрудника, которому нельзя писать в заявке КСП',
          },
        ],
        initialForm: {
          accountId: null
        },
        form: null,
      };
    },
  
    computed: {
      ...mapState({
        employeeAccounts: (state) => state.common.employeeAccounts.data,
      }),
  
      fields() {
        return [
          {
            form: [
              {
                type: 'select',
                key: 'accountId',
                label: 'ФИО сотрудника',
                list: this.employeeAccounts
              },
            ],
          },
        ];
      },
    },
    
    created() {
      this.getCommonList({ name: 'EmployeeAccounts' });
    },
  
    methods: {
      ...mapActions({
        createItem: 'editableList/createItem',
        getCommonList: 'common/getCommonList',
      }),

      validateForm(form, mandatoryFields) {
      const emptyField = mandatoryFields.filter((item) => {
        return !form[item.key];
      });
      let message = null;
      if (emptyField.length) {
        message = `Не заполнены обязательные поля: ${emptyField
          .map((item) => item.label)
          .join(', ')}`;
      }
      return message;
    },

    onSave(route) {
      const mandatoryFields = [{ key: 'accountId', label: 'Id аккаунта' }];

      const header = this.validateForm(this.form, mandatoryFields);
      if (header) {
        this.$notify({
          header,
          type: 'error',
          timer: 5000,
        });
        return;
      }

      this.isLoadingPage = true;

      this.createItem({
        name: this.controllerName,
        payload: this.form,
      })
        .then((result) => {
          if (result.isSucceed) {
            this.$router.push({ name: route });
          }
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    },
    },
  };
  </script>
  